














import { Component, Vue } from "vue-property-decorator";
import Navigation from "@views/components/Navigation.vue";
import ClaimCreationForm from "@views/components/ClaimCreationForm.vue";
@Component({
  components: {
    Navigation,
    ClaimCreationForm
  }
})
export default class IntakeFormPage extends Vue {}
