





















































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios, { AxiosResponse, AxiosError } from "axios";
import {
  MIN_PERSON_NAME_LENGTH,
  MAX_PERSON_NAME_LENGTH,
  PERSON_NAME_REGEX,
  LINE_OF_BUSINESS_FILER
} from "../../services/config/constants";
import dateformat from "../../util/date-format";
import { getCSRFToken, extractErrorMessage } from "../../services/actions";
import { User } from "../../datatypes/User";
import { Category } from "../../datatypes/Category";
import { ClaimConfirmation } from "../../datatypes/Claim";

import { removeDiacritics, nonEmptyString } from "../../util/string";

import get from "lodash/get";
import flatten from "lodash/flatten";
import { scrollBehavior } from "../../util/dom";
import has from "lodash/has";
import { rules } from "../../util/validation";
import isPlainObject from "lodash/isPlainObject";
import capitalize from "lodash/capitalize";
import { LineOfBusiness } from "../../datatypes/LineOfBusiness";

enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

@Component
export default class ClaimCreationForm extends Vue {
  csrfToken: string = "";
  firstname: string = "";
  category: number | null = null;
  lob: number | null = null;
  categories: Category[] = [];
  lineOfBusinesses: LineOfBusiness[] = [];
  status: Status = Status.IDLE;
  loading: boolean = false;
  confirmation: ClaimConfirmation | null = null;
  errors: string[] = [];
  formerrors: Record<string, string[]> = {};

  mounted() {
    this.getCategories();
    this.getLineOfBusinesses();
    this.getCSRFToken();
  }

  get user(): User | null {
    return this.$store.getters["session/user"];
  }

  get environmentmode(): string | null {
    if (
      this.user &&
      isPlainObject(this.user) &&
      nonEmptyString(this.user.Mode)
    ) {
      return capitalize(this.user.Mode.trim());
    }

    return null;
  }

  get constants() {
    return {
      MIN_PERSON_NAME_LENGTH,
      MAX_PERSON_NAME_LENGTH,
      PERSON_NAME_REGEX
    };
  }

  reset() {
    this.getCSRFToken();
    this.firstname = "";
    this.category = null;
    this.lob = null;
    this.status = Status.IDLE;
    this.loading = false;
    this.confirmation = null;
    this.errors = [];
    const $form = document.getElementById("claim-creation") as HTMLFormElement;
    if ($form) {
      $form.reset();
    }
  }

  private formattimestamp(timestamp: number) {
    return dateformat("P", timestamp);
  }

  private getCategoryById(categoryId: number) {
    const category = this.categories.find(c => Number(c.ID) === categoryId);
    return category ? category.Name : "";
  }

  validation() {
    const categoryIds = new Set(this.categories.map(c => Number(c.ID)));
    const lineOfBusinessIds = new Set(
      this.lineOfBusinesses.map(l => Number(l.ID))
    );

    this.formerrors = ["firstname", "category"].reduce(
      (errorbag: Record<string, string[]>, field: string) => {
        switch (field) {
          case "firstname": {
            const isvalid = rules.string.name(get(this.$data, field));
            return isvalid
              ? errorbag
              : { ...errorbag, firstname: ["Valid first name is required"] };
          }

          case "category": {
            const value = get(this.$data, field);
            const isvalid =
              value && !isNaN(Number(value)) && categoryIds.has(value);
            return isvalid
              ? errorbag
              : {
                  ...errorbag,
                  category: ["Valid category code is required"]
                };
          }

          case "lob": {
            const value = get(this.$data, field);
            const isvalid =
              value && !isNaN(Number(value)) && lineOfBusinessIds.has(value);
            return isvalid
              ? errorbag
              : {
                  ...errorbag,
                  category: ["Valid line of business code is required"]
                };
          }

          default: {
            return errorbag;
          }
        }
      },
      {} as Record<string, string[]>
    );
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  submit() {
    this.errors = [];
    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.errors = flatten(Object.values(this.formerrors));
      scrollBehavior("#claim-creation-errors");
      console.error("[claim creation form errors]", this.errors);
      return;
    }

    const confirmation = confirm("Please confirm the details below.");
    if (confirmation) {
      this.status = Status.SUBMITTING;
      this.loading = true;

      axios({
        url: "/api/v1/claims",
        method: "POST",
        data: {
          firstname: removeDiacritics(this.firstname),
          category: this.category,
          lob: this.lob,
          _csrf: this.csrfToken
        }
      })
        .then((response: AxiosResponse<ClaimConfirmation>) => {
          this.status = Status.SUCCESS;
          this.loading = false;
          const confirmation: ClaimConfirmation = {
            LineOfBusiness: response.data.LineOfBusiness,
            CreationTimestamp: Number(response.data.CreationTimestamp),
            ExpirationTimestamp: Number(response.data.ExpirationTimestamp),
            SID: response.data.SID
          };
          this.confirmation = confirmation;
        })
        .catch((err: AxiosError) => {
          this.getCSRFToken();
          this.status = Status.IDLE;
          this.loading = false;
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#claim-creation-errors");
          console.error("[claim creation request] Error", err.response);
        });
    }
  }

  getCategories() {
    axios("/api/v1/categories")
      .then(response => {
        this.categories = response.data;
      })
      .catch((err: AxiosError) => {
        this.categories = [];
        this.errors = this.errors.concat(extractErrorMessage(err));
        scrollBehavior("#claim-creation-errors");
        console.error("[categories list request] Error", err);
      });
  }

  getLineOfBusinesses() {
    axios("/api/v1/line-of-businesses")
      .then(response => {
        this.lineOfBusinesses = response.data.filter(
          (l: LineOfBusiness) => !LINE_OF_BUSINESS_FILER.has(l.Name)
        );
      })
      .catch((err: AxiosError) => {
        this.lineOfBusinesses = [];
        this.errors = this.errors.concat(extractErrorMessage(err));
        scrollBehavior("#claim-creation-errors");
        console.error("[line of businesses list request] Error", err);
      });
  }

  private async copySIDToClipboard() {
    if (!this.confirmation) {
      return;
    }
    const SID = this.confirmation.SID;
    try {
      await navigator.clipboard.writeText(SID);
    } catch (error) {
      alert("Failed to copy confirmation ID to clipboard");
      console.error(
        "failed to copy confirmation ID to clipboard",
        error,
        SID,
        navigator.clipboard
      );
    }
  }

  beforeDestroy() {
    this.reset();
    this.categories = [];
    this.lineOfBusinesses = [];
  }
}
